
import gptModule from '@/store/modules/gptModule';
import { getComponent, formatDateAndTime } from '@/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FileUpload from '@/components/gpt/FileUpload.vue';
import { ScrollPicker, ScrollPickerGroup } from 'vue-scroll-picker';
import 'vue-scroll-picker/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';
import gaugeModule from '@/store/modules/gaugeModule';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    GptSidebarPopup: () => getComponent('gpt/GptSidebarPopup'),
    AddGuageForm: () => getComponent('gpt/AddGuageForm'),
    PopupView: () => getComponent('tasqsCommon/PopupView'),
    FileUpload,
    ScrollPicker,
    ScrollPickerGroup,
  },
})
export default class GuageTankDetail extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;
  @Prop({ type: Object, required: true }) tankGauge?: any;

  rowData: any = [];

  isUploadingFile = false;

  dataLoading = false;

  showAddGuageForm = false;

  selectedGaugeEntry: any = null;

  addRunTicket = false;

  sortPayloadsByTime(payloads: any) {
    return payloads.slice().sort((a: any, b: any) => {
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  }

  findLatestObjectByTime(records) {
    return records.reduce((latest, current) => {
      return new Date(latest.time) > new Date(current.time) ? latest : current;
    }, records[0]);
  }

  dataInProcess = false;

  attachRunTicketPath(s) {
    if (s.length) {
      this.dataInProcess = true;
      this.uploadFileToRockset(s[0]);
      // if (!this.selectedGaugeEntry) {
      //   this.selectedGaugeEntry = this.findLatestObjectByTime(this.gaugesData);
      // }
      // this.selectedGaugeEntry = JSON.parse(
      //   JSON.stringify(this.selectedGaugeEntry)
      // );
      // this.selectedGaugeEntry.run_ticket_link = s[0].url;
      // this.selectedGaugeEntry.run_ticket_datetime = new Date().toISOString();
    }
    this.isUploadingFile = false;
  }

  addingGuageForm = false;

  DATA1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  DATA2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  DATA3 = ['FT'];
  DATA4 = ['IN'];
  DATA5 = [0, 1, 2, 3];
  DATA6 = ['QTR IN'];

  getLatestMeasurement(payloads, suppliedId, key) {
    let latestMeasurement = null;

    payloads.forEach((payload) => {
      if (payload._id !== suppliedId) {
        if (
          !latestMeasurement ||
          new Date(payload.time) > new Date(latestMeasurement.time)
        ) {
          latestMeasurement = payload;
        }
      }
    });

    return latestMeasurement ? latestMeasurement[key] : null;
  }

  useLastWaterLevelGuageValue(data) {
    const ds = this.getLatestMeasurement(
      this.gaugesData,
      data._id,
      'water_level_open'
    );
    if (ds) {
      const newGuageData: any = this.gaugesData.map((item) => {
        if (item._id === data._id) {
          item['water_level_open'] = JSON.parse(JSON.stringify(ds));
        }
        return {
          ...item,
        };
      });
      this.gaugesData = this.sortPayloadsByTime(newGuageData);
    }
  }

  useLastMeasLevelGuageValue(data) {
    const ds = this.getLatestMeasurement(
      this.gaugesData,
      data._id,
      'measurement_level_open'
    );
    if (ds) {
      const newGuageData: any = this.gaugesData.map((item) => {
        if (item._id === data._id) {
          item['measurement_level_open'] = JSON.parse(JSON.stringify(ds));
        }
        return {
          ...item,
        };
      });
      this.gaugesData = this.sortPayloadsByTime(newGuageData);
    }
  }

  addGuageForm() {
    const newGuageEntry = {
      _id: uuidv4(),
      time: new Date().toISOString(),
      measurement_level_open: {
        ft: 0,
        in_: 0,
        qtr_in: 0,
      },
      water_level_open: {
        ft: 0,
        in_: 0,
        qtr_in: 0,
      },
      last_change: '24-01-10',
      run_ticket_link: '',
      total_volume_bbl: 0,
      updated_at: new Date().toISOString(),
      updated_by: accountModule.user.email,
      delta_from_last_volume: 0,
      isNewUnsavedRow: true,
    };
    this.gaugesData = this.gaugesData = this.sortPayloadsByTime([
      newGuageEntry,
      ...this.gaugesData,
    ]);
    this.addingGuageForm = true;
  }

  headers = [
    'Last Event Date & Time',
    'Meas. level',
    'last (+/-)',
    'Run ticket',
    'Current Volume (BBLS)',
    'oil/water level',
    'Action',
  ];

  convertMesuarementTotalbbls(measurement, dip_factor = 1.67) {
    var totalInches = 0;

    // Convert feet to inches and add to total
    if (measurement.ft) {
      totalInches += measurement.ft * 12;
    }

    // Add inches to total
    if (measurement.in_) {
      totalInches += measurement.in_;
    }

    // Convert quarter inches to inches and add to total
    if (measurement.qtr_in) {
      totalInches += measurement.qtr_in * 0.25;
    }

    // If dip_factor is provided and is a positive number, multiply the total inches by it
    if (dip_factor && !isNaN(dip_factor) && dip_factor > 0) {
      totalInches *= dip_factor;
    }

    // Return the result as an integer
    return Math.round(totalInches);
  }

  calculateDelta(id) {
    const payload = this.gaugesData; // Your data
    const currentItemIndex = payload.findIndex((item) => item._id === id);
    const currentItem = payload[currentItemIndex];

    let previousItem = null;
    let smallestDiff = Infinity;

    if (currentItem) {
      payload.forEach((item) => {
        const diff =
          new Date(currentItem.updated_at).getTime() -
          new Date(item.updated_at).getTime();
        if (diff > 0 && diff < smallestDiff) {
          smallestDiff = diff;
          previousItem = item;
        }
      });

      if (previousItem) {
        const currentTimestamp = new Date(currentItem.updated_at).getTime();
        const previousTimestamp = new Date(previousItem.updated_at).getTime();

        if (currentTimestamp < previousTimestamp) {
          return null; // Current item updated before previous item, invalid scenario
        }

        const previousDelta = previousItem.run_ticket_link.includes(
          'https://schedule-zips.s3.amazonaws.com/'
        )
          ? this.convertMesuarementTotalbbls(
              previousItem.measurement_level_close
            )
          : this.convertMesuarementTotalbbls(
              previousItem.measurement_level_open
            );
        const currentDelta = currentItem.run_ticket_link.includes(
          'https://schedule-zips.s3.amazonaws.com/'
        )
          ? this.convertMesuarementTotalbbls(
              currentItem.measurement_level_close
            )
          : this.convertMesuarementTotalbbls(
              currentItem.measurement_level_open
            );

        const delta = currentDelta - previousDelta;
        return delta;
      }
    }
    return null;
  }

  convertStringToIntegers(obj) {
    // Define the keys to convert
    var keysToConvert = ['ft', 'in_', 'qtr_in'];

    for (var key in obj) {
      // Check if the current property exists in the object
      if (obj.hasOwnProperty(key)) {
        // If the value is an object, recurse
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.convertStringToIntegers(obj[key]);
        } else {
          // If the key is one of the specified keys, convert its value to integer
          if (keysToConvert.indexOf(key) !== -1) {
            obj[key] = parseInt(obj[key], 10);
            // In case of NaN (not a number), set it to 0 or any default value you see fit
            if (isNaN(obj[key])) {
              obj[key] = 0; // Default value if the conversion fails
            }
          }
        }
      }
    }
    return obj;
  }

  convertMeasurementInField(obj) {
    // Check if the input is an object
    if (typeof obj === 'object' && obj !== null) {
      // Iterate through the keys of the object
      for (const key in obj) {
        // Check if the value is an object itself (nested object)
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // If it is, recursively call the function on the nested object
          obj[key] = this.convertMeasurementInField(obj[key]);
        }
        // Check if the key is "in"
        if (key === 'in') {
          // If it is, rename the key to "in_"
          obj['in_'] = obj['in'];
          delete obj['in']; // Remove the old key
        }
      }
    }
    // Return the modified object
    return obj;
  }

  isFullScreen = false;

  selectedFileForPreview: any = null;

  openFilePreview(data) {
    this.isFullScreen = false;
    this.selectedFileForPreview = data;
  }

  closeFilePreview() {
    this.selectedFileForPreview = null;
    this.selectedFileForPreview = null;
  }

  setFullScreen() {
    this.isFullScreen;
  }

  tanks: any = [];

  gaugesData: any = [];

  async created() {
    this.dataLoading = true;
    this.dataLoading = false;
    if (this.tankGauge && this.tankGauge.data) {
      this.gaugesData = this.tankGauge.data
        .filter((t) => t.measurement_level_open)
        .map((d) => {
          return {
            ...d,
            _id: d._id || uuidv4(),
            isNewUnsavedRow: false,
          };
        });
      this.gaugesData = this.sortPayloadsByTime(this.gaugesData);
    }
  }

  cancelGuageForm() {
    this.gaugesData = this.gaugesData.filter((t) => !t.isNewUnsavedRow);
    this.addingGuageForm = false;
    this.addRunTicket = false;
  }

  attachRunTicket(data) {
    this.isUploadingFile = true;
    this.selectedGaugeEntry = data;
    this.addRunTicket = true;
  }

  saveGaugeForm() {
    this.gaugesData = this.gaugesData.map((t) => {
      return {
        ...t,
        isNewUnsavedRow: false,
      };
    });
    this.addingGuageForm = false;
    this.addRunTicket = false;
    this.$emit('update-data', this.convertStringToIntegers(this.gaugesData));
  }

  formatDateAndTime(date) {
    const dateObj = new Date(date);
    return formatDateAndTime(dateObj);
  }

  saveRunTicket() {
    if (this.selectedGaugeEntry) {
      const gaugesData = this.gaugesData.map((d) => {
        if (this.selectedGaugeEntry._id === d._id) {
          return this.selectedGaugeEntry;
        }
        return d;
      });

      this.gaugesData = gaugesData;
    }
    this.$emit('update-data', this.gaugesData);
    this.selectedGaugeEntry = null;
    this.addRunTicket = false;
  }

  async uploadFileToRockset(data) {
    this.dataInProcess = true;
    const operatorDetails = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );
    const metaData = {
      url: data.url,
      wellMetaData: {
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
        nodeid: this.tasq.wellName,
        level:
          this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well',
      },
    };

    const tryUpload = async () => {
      const response = await gaugeModule.uploadFiles(metaData);

      if (response.measurement_level_close) {
        this.$tasqAlert({
          title: 'TASQ AI',
          message: 'File processing is complete',
          type: 'success',
        });

        console.log(this.convertMeasurementInField(response));
        this.gaugesData = this.gaugesData = this.sortPayloadsByTime([
          {
            ...this.convertMeasurementInField(response),
            isNewUnsavedRow: true,
            updated_by: accountModule.user.email,
            updated_at: new Date(response.run_ticket_datetime).toISOString(),
          },
          ...this.gaugesData,
        ]);
      }
      this.dataInProcess = false;
    };
    tryUpload(); // Initial call
  }

  deleteTicketData(ticket) {
    const { _id } = ticket;
    this.gaugesData = this.gaugesData.filter((t) => t._id !== _id);
    this.saveGaugeForm();
  }
}
